import { FinishComponent } from './pages/finish/finish.component';
import { AdminComponent } from './pages/admin/admin.component';
import { PlannerComponent } from './pages/planner/planner.component';
import { ConfiguratorComponent } from './pages/configurator/configurator.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: 'planner', component: PlannerComponent },
  { path: 'config', component: ConfiguratorComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'finish', component: FinishComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
