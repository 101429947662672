<mat-toolbar>
   <div class="container">
    <h1>{{'app.components.toolbar.title' | translate}}</h1>
    <ul>
      <li>
        <a href="https://" target="_blank" rel="noopener noreferrer">{{'app.components.toolbar.privacy' | translate}}</a>
      </li>
      <li>
        <a href="https://" target="_blank" rel="noopener noreferrer">{{'app.components.toolbar.contact' | translate}}</a>
      </li>
      <li>
        <a href="https://" target="_blank" rel="noopener noreferrer">{{'app.components.toolbar.imprint' | translate}}</a>
      </li>
    </ul>
  </div>
</mat-toolbar>
