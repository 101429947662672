  <svg *ngIf="(hasRoute | async) !== null" width="100%" height="100%">
    <line *ngFor="let lines of routing.steps; let first = first; let i = index;"
      [attr.x1]="getDirection(lines.itemid, first, 'x1', i)" [attr.y1]="getDirection(lines.itemid, first, 'y1', i)"
      [attr.x2]="getDirection(lines.itemid, first, 'x2', i)" [attr.y2]="getDirection(lines.itemid, first, 'y2', i)"
      stroke-dasharray="8" stroke-width="4" [attr.stroke]="routing.steps.length > 1 ? '#00549f' : '#178038' " />
    <line *ngIf="routing.steps[1]" [attr.x1]="'22px'" y1="0"
      [attr.x2]="getDirectRoute(routing.steps[routing.steps.length - 1].itemid, 'x')"
      [attr.y2]="getDirectRoute(routing.steps[routing.steps.length - 1].itemid, 'y')" stroke-dasharray="8"
      stroke-width="4" stroke="#178038"></line>
  </svg>

  <div class="route-info" *ngIf="routing">
  <div class="box" [ngStyle]="{'background-color' : routing.steps.length > 1 ? '#00549f' : '#178038'}" >
    Route 1
  </div>
  <div *ngIf="routing.steps.length > 1" class="box" [ngStyle]="{'background-color' : '#178038'}" >
    Route 2
  </div>
</div>
