import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { StatisticService } from './../../services/statistic.service';
import { IStatistic } from './../../interfaces/statistic.interface';
import * as StatisticActions from 'src/app/store/statistic.actions';
import * as GoalActions from 'src/app/store/goal.actions';
import { SnackBarService } from './../../services/snackbar.service';
import { ListResponse } from './../../interfaces/base/response';
import { ISearchParams } from './../../interfaces/serachParams';
import { BusinessModelService } from './../../services/businessmodel.service';
import { IBusinessmodel, IRoute } from './../../interfaces/businessmodel';
import { BehaviorSubject, from, Observable, Subject, Subscription, timer } from 'rxjs';
import { PlannerModalComponent } from './../../components/modals/planner-modal/planner-modal.component';
import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as fromApp from 'src/app/store/app.reducer';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import * as ModelActions from 'src/app/store/models.actions';

import * as SelectedModelActions from 'src/app/store/selectedModel.actions';
import { MatSliderChange } from '@angular/material/slider';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  find,
  map,
  mapTo,
  switchMap,
  take,
} from 'rxjs/operators';
import { startWith } from 'rxjs/operators/startWith';

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import * as rasterizeHTML from 'rasterizehtml';

import * as domtoimage from 'dom-to-image-improved';
import { DeleteGoal } from 'src/app/store/goal.actions';
import { DeleteRoute } from 'src/app/store/route.actions';
import { Options } from '@angular-slider/ngx-slider';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
})
export class PlannerComponent implements OnInit {
  modelStore: Observable<any>;
  selectedModelStore: Observable<any>;
  goalStore: Observable<IBusinessmodel>;
  routeStore: Observable<IRoute>;
  selectedModel: IBusinessmodel = {
    title_german: null,
  };

  zoomLevel = 1;

  showInfo = 0;

  subjectAreas = {
    area1: false,
    area2: false,
    area3: false,
    area4: false,
  };

  searchParams: ISearchParams = {
    maxDistance: 100,
    subjectAreas: [],
  };

  maxDistance = 100;
  minDistance = 0;

  valueSubject = new BehaviorSubject<number>(0);

  showmenu = true;

  screenshot = '';

  isLocked = false;
  options: Options = {
    floor: 0,
    ceil: 100,
  };

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  optionsBM: any = [];

  env = environment;

  constructor(
    private dialog: MatDialog,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private bmService: BusinessModelService,
    private sbService: SnackBarService,
    private statService: StatisticService,
  ) {
    this.modelStore = this.store.select('models');
    this.goalStore = this.store.select('goal');
    this.selectedModelStore = this.store.select('selectedModel');
    this.filteredOptions = this.store.select('models');
    this.routeStore = this.store.select('route');
    this.selectedModelStore.subscribe((res) => {
      this.selectedModel = res;
    });

    this.modelStore
      .pipe(
        map((models: IBusinessmodel[]) => {
          const modelArray = [];
          models.forEach((element) => {
            modelArray.push(element.title_german);
          });
          return modelArray;
        }),
      )
      .subscribe((res) => {
        this.optionsBM = res;
      });

    router.events.subscribe((event: Event) => {
      // see also
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        if (!window.location.href.includes('goal')) {
          this.store.dispatch(new DeleteGoal());
          this.store.dispatch(new DeleteRoute());
          this.isLocked = true;
        } else {
          this.isLocked = false;
          this.valueSubject.pipe(debounceTime(1000)).subscribe((value) => {
            // http request can go here
            this.getPins();
          });
          this.getPins();
        }
      }
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val)),
    );
  }

  ngOnInit(): void {
    this.dialog.open(PlannerModalComponent, {
      backdropClass: 'blueBackdrop',
    });

    this.valueSubject.pipe(debounceTime(1000)).subscribe((value) => {
      // http request can go here
      this.getPins();
    });
    this.getPins();
  }

  distanceChanged(event, isMinDistance: boolean) {
    if (isMinDistance) {
      this.searchParams.minDistance = event;
    } else {
      this.searchParams.maxDistance = event;
    }
    this.valueSubject.next(event);
  }

  zoom() {
    this.zoomLevel = this.zoomLevel + 0.1;
  }

  zoomOut() {
    this.zoomLevel = this.zoomLevel - 0.1;
  }

  download() {
    let element = document.querySelector('body');
    this.showmenu = false;

    this.goalStore.subscribe((res) => {
      const statistic: IStatistic = {
        target_bm: Number(res.itemid),
        min_distance: this.minDistance,
        max_distance: this.maxDistance,
        subject_areas_filters: this.searchParams.subjectAreas,
      };
      this.store.dispatch(new StatisticActions.UpdateStatistic(statistic));
      this.store.select('statistic').subscribe((res) => {
        if (res.start_bm) {
          this.statService.save(res).subscribe();
        }
      });
    });

    // this.screenshot = window.location.href;
    // rasterizeHTML.drawHTML(, canvas);

    this.sbService.primary('Lade Wanderführer..', null, 1000);

    const scale = 2;

    setTimeout(() => {
      domtoimage.toBlob(document.body, { style: { height: '1080px' } }).then(function (blob) {
        saveAs(blob, 'wanderfuehrer.png');
      });

      this.bmService.getPDF({ value: 1 }).subscribe((res) => {
        saveAs(res, 'Wanderfuehrer_einfach.pdf');
      });
      this.bmService.getPDF({ value: 2 }).subscribe((res) => {
        saveAs(res, 'Wanderfuehrer_anspruchsvoll.pdf');
      });
      this.bmService.getPDF({ value: 3 }).subscribe((res) => {
        saveAs(res, 'Wanderfuehrer_herausfordernd.pdf');
        this.router.navigate(['/finish']);
      });
    }, 2000);
  }

  toggle(index: number) {
    switch (index) {
      case 0:
        this.subjectAreas.area1 = !this.subjectAreas.area1;
        if (this.subjectAreas.area1) {
          this.searchParams.subjectAreas.push(0);
        } else {
          this.searchParams.subjectAreas = this.searchParams.subjectAreas.filter(
            (area) => area !== 0,
          );
        }
        this.getPins();
        break;
      case 1:
        this.subjectAreas.area2 = !this.subjectAreas.area2;
        if (this.subjectAreas.area2) {
          this.searchParams.subjectAreas.push(1);
        } else {
          this.searchParams.subjectAreas = this.searchParams.subjectAreas.filter(
            (area) => area !== 1,
          );
        }
        this.getPins();
        break;
      case 2:
        this.subjectAreas.area3 = !this.subjectAreas.area3;
        if (this.subjectAreas.area3) {
          this.searchParams.subjectAreas.push(2);
        } else {
          this.searchParams.subjectAreas = this.searchParams.subjectAreas.filter(
            (area) => area !== 2,
          );
        }
        this.getPins();
        break;
      case 3:
        this.subjectAreas.area4 = !this.subjectAreas.area4;
        if (this.subjectAreas.area4) {
          this.searchParams.subjectAreas.push(3);
        } else {
          this.searchParams.subjectAreas = this.searchParams.subjectAreas.filter(
            (area) => area !== 3,
          );
        }
        this.getPins();

        break;
    }
  }

  getPins() {
    this.bmService
      .getRoutingMap(this.route.snapshot.queryParams.model, this.searchParams)
      .subscribe((res: ListResponse<IBusinessmodel>) => {
        this.selectedModel.class = 'center';

        let models = res.list;
        const mergedModels = res.list.push(this.selectedModel);
        for (let index = 0; index < res.list.length; index++) {
          const model = models[index];
          model.index = index;
          // model.x = Math.floor(Math.random() * 31) + 50;
          // model.y = Math.floor(Math.random() * 70) + 10;
        }
        this.store.dispatch(new ModelActions.UpdateModel(res.list));
      });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.store
      .select('models')
      .pipe(
        map((models: IBusinessmodel[]) => {
          return models.find((model) => model.title_german === event.option.value);
        }),
      )

      .subscribe((res) => {
        this.store.dispatch(new GoalActions.UpdateGoal(res));

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            model: this.selectedModel.itemid,
            goal: res.itemid,
          },
        });
      });
  }

  checkInput() {
    if (this.optionsBM.includes(this.myControl.value)) {
      this.store
        .select('models')
        .pipe(
          map((models: IBusinessmodel[]) => {
            return models.find((model) => model.title_german === this.myControl.value);
          }),
        )

        .subscribe((res) => {
          this.store.dispatch(new GoalActions.UpdateGoal(res));

          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              model: this.selectedModel.itemid,
              goal: res.itemid,
            },
          });
        });
    }
  }

  private filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.optionsBM.filter((street) => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
