import { ISearchParams } from './../interfaces/serachParams';
import { HelperService } from './helper.service';
import { ListResponse, DetailResponse } from './../interfaces/base/response';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from './generic/crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BusinessModelService extends CrudService {
  // get api url from environment file (The field is extension of the super class field)
  protected url = environment.api + '/businessmodel';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getRoutingMap(id: string, searchParams: ISearchParams): Observable<ListResponse<any>> {
    const httpParams = HelperService.getHttpParams(searchParams);
    return this.httpClient.get<ListResponse<any>>(`${this.url}/${id}/map`, {
      params: httpParams,
      headers: this.jsonHeaders.headers,
    });
  }

  getDistance(id: string, searchParams?: ISearchParams): Observable<DetailResponse<any>> {
    const httpParams = HelperService.getHttpParams(searchParams);
    return this.httpClient.get<DetailResponse<any>>(`${this.url}/${id}/map/distance`, {
      params: httpParams,
      headers: this.jsonHeaders.headers,
    });
  }

  getRoute(searchParams) {
    const httpParams = HelperService.getHttpParams(searchParams);
    return this.httpClient.get<DetailResponse<any>>(`${this.url}/route`, {
      params: httpParams,
      headers: this.jsonHeaders.headers,
    });
  }

  getPDF(searchParams) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    const httpParams = HelperService.getHttpParams(searchParams);
    return this.httpClient.get<DetailResponse<any>>(`${this.url}/pdf`, {
      params: httpParams,
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }
}
