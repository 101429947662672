import * as RouteActions from './route.actions';

let initialState = null;

export function routeStore(state = initialState, action: RouteActions.RouteActions) {
  switch (action.type) {
    case RouteActions.UPDATE_ROUTE:
      return {
        ...state,
        ...action.payload,
      };

    case RouteActions.DELETE_ROUTE:
      return initialState;

    default:
      return state;
  }
}
