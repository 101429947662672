import * as ModelActions from './models.actions';

const initialState = [];

export function mapStore(state = initialState, action: ModelActions.ModelsActions) {
  switch (action.type) {
    case ModelActions.UPDATE_MODELS:
      return [...action.payload];

    // {
    // ...action.payload,
    // };

    case ModelActions.DELETE_MODELS:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}
