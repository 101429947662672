import { DynamicEnvironment } from './dynamic-enviroments';
class Enviroment extends DynamicEnvironment {
  public production: boolean;
  public appVersion: string;
  public mogree: boolean;
  constructor() {
    super();

    this.appVersion = require('../../package.json').version + '-stage';
    this.production = true;
    this.mogree = false;
  }
}

export const environment = new Enviroment();
