import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from './../../services/snackbar.service';
import { ListResponse } from './../../interfaces/base/response';
import { BusinessModelService } from './../../services/businessmodel.service';
import { IBusinessmodel } from './../../interfaces/businessmodel';
import { Observable } from 'rxjs';
import { BusinessModelDetailComponent } from './../../components/modals/business-model-detail/business-model-detail.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import * as ModelsActions from './../../store/models.actions';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
})
export class ConfiguratorComponent implements OnInit {
  readonly defaultFilterSettings = {
    filter1: -1,
    filter2: -1,
    filter3: -1,
    filter4: -1,
  };

  isLoading = false;

  filterSettings = {
    market_filter: -1,
    revenue_model_filter: -1,
    sales_filter: -1,
    value_proposition_filter: -1,
  };

  isFiltered = false;
  filterPressed = false;

  models: Observable<IBusinessmodel[]>;

  constructor(
    private dialog: MatDialog,
    private store: Store<fromApp.AppState>,
    private bmService: BusinessModelService,
    private sbService: SnackBarService,
    private translate: TranslateService,
  ) {
    this.models = this.store.select('models');
  }

  checkFilters() {
    // if (
    // this.filterSettings.market_filter !== 'default' &&
    // this.filterSettings.revenue_model_filter !== 'default' &&
    // this.filterSettings.sales_filter !== 'default' &&
    // this.filterSettings.value_proposition_filter !== 'default'
    // ) {
    this.isFiltered = true;
    this.filter();
    // }
  }

  reset() {
    this.filterSettings.market_filter = -1;
    this.filterSettings.revenue_model_filter = -1;
    this.filterSettings.sales_filter = -1;
    this.filterSettings.value_proposition_filter = -1;
    this.filterPressed = false;
    this.isFiltered = false;

    this.isLoading = true;
    this.bmService.findAll().subscribe((res: ListResponse<IBusinessmodel>) => {
      let models = res.list;
      for (let index = 0; index < models.length; index++) {
        const model = models[index];
        model.index = index;
        model.x = Math.floor(Math.random() * 100) + 1;
        model.y = Math.floor(Math.random() * 100) + 1;
      }
      this.store.dispatch(new ModelsActions.UpdateModel(models));
      this.sbService.success(this.translate.instant('app.components.toast.success'), null, 4000);
      this.isLoading = false;
    });
  }

  filter() {
    this.isLoading = true;
    this.filterPressed = true;
    this.bmService.findAll(this.filterSettings).subscribe((res: ListResponse<IBusinessmodel>) => {
      let models = res.list;
      for (let index = 0; index < models.length; index++) {
        const model = models[index];
        model.index = index;
        model.x = Math.floor(Math.random() * 100) + 1;
        model.y = Math.floor(Math.random() * 100) + 1;
      }
      this.store.dispatch(new ModelsActions.UpdateModel(models));
      this.sbService.success(this.translate.instant('app.components.toast.success'), null, 4000);
      this.isLoading = false;
    });
  }

  openDialog(model) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.maxWidth = '1000px';

    dialogConfig.data = {
      model: model,
      filters: this.filterSettings,
    };
    dialogConfig.backdropClass = 'blueBackdrop';
    dialogConfig.disableClose = true;

    this.dialog.open(BusinessModelDetailComponent, dialogConfig);
  }

  ngOnInit(): void {}
}
