import { IBusinessmodel } from './../../../interfaces/businessmodel';
import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store/app.reducer';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() hasOverlay: boolean;
  @Input() isHome: boolean;
  @Input() zoomLevel: number;
  @Input() isPlanner: boolean;
  @Input() isLocked: boolean;

  updatePins: boolean;

  pins: Observable<IBusinessmodel[]>;

  goal: Observable<IBusinessmodel>;

  constructor(private store: Store<fromApp.AppState>) {
    if (window.location.href.includes('/planner')) {
      this.pins = this.store.select('models');
    } else {
      this.pins = this.store.select('map');
    }
    this.goal = this.store.select('goal');
  }

  ngOnInit(): void {}

  markerClicked(event) {
    this.updatePins = event;
  }
}
