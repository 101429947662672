import { IBusinessmodel } from './interfaces/businessmodel';
import { ListResponse } from './interfaces/base/response';
import { BusinessModelService } from './services/businessmodel.service';
import { IntroSlideshowComponent } from './components/modals/intro-slideshow/intro-slideshow.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import * as ModelActions from 'src/app/store/models.actions';
import * as StartpageActions from 'src/app/store/startpage.actions';
import * as SelectedModelActions from './store/selectedModel.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { map, tap } from 'rxjs/operators';
import { NgxCaptureService } from 'ngx-capture';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ng-mogree starter';

  @ViewChild('screen', { static: true }) screen: any;

  startpageCounter = -1;
  positions = [
    { x: 60, y: 30 },
    { x: 76, y: 60 },
    { x: 83, y: 32 },
    { x: 71, y: 33 },
    { x: 67, y: 66 },
    { x: 81, y: 44 },
    { x: 89, y: 58 },
    { x: 64, y: 50 },
    { x: 52, y: 69 },
    { x: 79, y: 20 },
    { x: 55, y: 40 },
    { x: 50, y: 55 },
    { x: 56, y: 56 },
    { x: 50, y: 50 },
    { x: 45, y: 70 },
  ];

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private bmSerivce: BusinessModelService,
    private store: Store<fromApp.AppState>,
    private captureService: NgxCaptureService,
  ) {
    translate.setDefaultLang('de');
    translate.use(translate.getBrowserLang());
  }
  ngOnInit() {
    if (
      !window.location.href.includes('/config') &&
      !window.location.href.includes('/planner') &&
      !window.location.href.includes('/finish') &&
      !window.location.href.includes('/admin')
    ) {
      this.dialog.open(IntroSlideshowComponent, {
        backdropClass: 'blueBackdrop',
        disableClose: true,
      });
    }
    this.bmSerivce.findAll().subscribe((res: ListResponse<IBusinessmodel>) => {
      let models = res.list;
      for (let index = 0; index < models.length; index++) {
        const model = models[index];

        model.index = index;

        if (model.startpage === true) {
          this.startpageCounter = this.startpageCounter + 1;
          model.x = this.positions[this.startpageCounter].x;
          model.y = this.positions[this.startpageCounter].y;
        }
      }

      this.store.dispatch(new StartpageActions.UpdateStartpage(models));

      this.store.dispatch(new ModelActions.UpdateModel(res.list));
      if (this.route.snapshot.queryParams.model) {
        this.store.dispatch(
          new SelectedModelActions.UpdateModel(
            res.list.find((x) => x.itemid === this.route.snapshot.queryParams.model),
          ),
        );
      }
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  saveImage(img: string) {}

  capture() {
    this.captureService
      .getImage(this.screen.nativeElement, true)
      .pipe(tap((img) => {}))
      .subscribe((res) => {});
  }
}
