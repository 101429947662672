import { Action } from '@ngrx/store';

export const UPDATE_MODELS = 'UPDATE_MODELS';

export class UpdateStartpage implements Action {
  readonly type = UPDATE_MODELS;
  constructor(public payload) {}
}

export type ModelsActions = UpdateStartpage;
