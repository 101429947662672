<div class="wrapper" *ngIf="!env.mogree">
  <div class="sidemenu"  >
    <div class="info">
      <div class="icons">
        <img src="../../../assets/images/route.jpg" alt="">
        <img src="../../../assets/images/walk.jpg" alt="">
      </div>
      <p class="download-info">Die gewählten Routen und Ihr Wanderführer <br> sind nun in Ihren Downloads gesichert.</p>
    </div>
    <div class="info">
      <h3 class="center blue">Wir wandern gerne mit Ihnen:</h3>
      <p class="center fine">Kontaktieren Sie uns für Ihre Geschäftsmodell-Veränderung.</p>
      <div class="logos">
        <div>
          <a href="https://www.campus02.at/" target="_blank">
            <img src="../../../assets/images/Logo_col.png.png" alt="Campus 02">
          </a>
          <a href="https://www.campus02.at/innovationsmanagement/" target="_blank">
            <img src="../../../assets/images/Wappen_gezeichnet_FINAL.png" alt="Campus 02">
            Studienrichtung Innovationsmanagement
          </a>
        </div>
        <div>
          <a href="https://www.innolab.at/" target="_blank">
            <img src="../../../assets/images/innolab_Logo_Blau.png" alt="Campus 02">
          </a>
          <a href="https://www.innolab.at/" target="_blank">INNOLAB der WKO Steiermark</a>
        </div>
      </div>
      <div class="info-text">
        <p>BM-MAPS ist eine Kooperation der FH CAMPUS 02, Studienrichtung Innovationsmanagement, der RWTH Aachen, Institut für Technologie- und Innovationsmanagement (TIM) und des INNOLAB der Wirtschaftskammer Steiermark.</p>
      </div>
    </div>
  </div>
  <img class="background" src="../../../assets/images/map.jpg" alt="map">
</div>

<div class="mogree" *ngIf="env.mogree" >

  <div class="container">
    <div class="info">
      <a href="https://mogree.com" target="_blank">
        <img src="../../../assets/images/mogree.png" alt="mogree">
      </a>
      <br>
      <p class="small">Neue Geschäftsmodelle, digitale Services,  Automatisierung von Prozessen, innovative Ideen u.v.m. Mit unserer externen Sichtweise und Expertise helfen wir Ihnen neue Möglichkeiten zu identifizieren und Potenziale zu erschließen.</p>
      <br>
      <p class="small">Lassen Sie uns über Ihre digitale Herausforderung reden – wir freuen uns über Ihre Kontaktaufnahme!</p>
      <div class="styled-subtitle">
        <span class="first" ><span ><strong>Tel:</strong> <a href="tel:00437203033010">+43 720 303 301 – 0 </a></span></span>
        <span ><span ><strong>E-Mail:</strong> <a href="mailto:hello@mogree.com">hello@mogree.com</a></span></span>
      </div>
    </div>
  </div>
  </div>

