import { Action } from '@ngrx/store';

export const UPDATE_STATISTIC = 'UPDATE_STATISTIC';

export class UpdateStatistic implements Action {
  readonly type = UPDATE_STATISTIC;
  constructor(public payload) {}
}

export type StatisticActions = UpdateStatistic;
