import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planner',
  templateUrl: './planner-modal.component.html',
  styleUrls: ['./planner-modal.component.scss'],
})
export class PlannerModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
