import { IStatistic } from './../interfaces/statistic.interface';
import { ActionReducerMap } from '@ngrx/store';
import * as modelStore from './models.reducer';
import * as selectedModel from './selectedModel.reducer';
import * as startpage from './startpage.reducer';
import * as goalStore from './goal.reducer';
import * as map from './map.reducer';
import * as route from './route.reducer';
import * as statistic from './statistic.reducer';

export interface AppState {
  models: any[];
  selectedModel: any;
  startpage: any;
  map: any[];
  goal: any;
  route: any;
  statistic?: IStatistic;
}

export const appReducer: ActionReducerMap<any> = {
  models: modelStore.modelStore,
  selectedModel: selectedModel.selectedModel,
  startpage: startpage.startpageStore,
  map: map.mapStore,
  goal: goalStore.goalStore,
  route: route.routeStore,
  statistic: statistic.statisticStore,
};
