import { ISearchParams } from './../interfaces/serachParams';
import { HelperService } from './helper.service';
import { ListResponse, DetailResponse } from './../interfaces/base/response';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from './generic/crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatisticService extends CrudService {
  // get api url from environment file (The field is extension of the super class field)
  protected url = environment.api + '/statistic';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getCSV() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.httpClient.get<DetailResponse<any>>(`${this.url}/export`, {
      headers: HelperService.getDefaultHeaders(),
      responseType: 'blob' as 'json',
    });
  }
}
