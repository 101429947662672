import { IStatistic } from './../interfaces/statistic.interface';
import * as StatisticActions from './statistic.actions';

let initialState: IStatistic = null;

export function statisticStore(state = initialState, action: StatisticActions.StatisticActions) {
  switch (action.type) {
    case StatisticActions.UPDATE_STATISTIC:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
