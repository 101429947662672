<div class="page">
  <header>
    <div class="background">
      <div class="overlay"></div>
    </div>
    <div class="container">
      <!-- <div class="back">
        <mat-icon>arrow_back</mat-icon>
        <a [routerLink]="['/']">{{'app.pages.configurator.back-button' | translate}}</a>
      </div> -->
      <div class="content">
        <h4>{{'app.pages.configurator.subtitle' | translate}}</h4>
        <h2>{{'app.pages.configurator.title' | translate}}</h2>
        <p>{{'app.pages.configurator.description' | translate}}</p>
      </div>
    </div>
  </header>
  <section>
    <div class="container">
      <p class="small">{{'app.pages.configurator.filter.description' | translate}}</p>
      <div class="filters">
        <div class="filter">
          <mat-icon>tune</mat-icon>
          <p>{{'app.pages.configurator.filter.title' | translate}}</p>
        </div>
        <div class="filter-options">
            <mat-select (selectionChange)="checkFilters()" [(ngModel)]="filterSettings.revenue_model_filter" >
              <mat-option [value]="defaultFilterSettings.filter1" disabled >{{'app.pages.configurator.filter.labels.label1' | translate}}</mat-option>
              <mat-option value="0">
                Produkt-/Serviceverkauf
              </mat-option>
              <mat-option value="1">
                Nutzungs-Gebühr
              </mat-option>
              <mat-option value="2">
                Abo & Mieten (regelmäßige Zahlung)
              </mat-option>
              <mat-option value="3">
                Lizensierung
              </mat-option>
              <mat-option value="4">
                Provision
              </mat-option>
              <mat-option value="5">
                Werbung
              </mat-option>
            </mat-select>

            <mat-select (selectionChange)="checkFilters()"  [(ngModel)]="filterSettings.value_proposition_filter" >
              <mat-option [value]="defaultFilterSettings.filter2" disabled >{{'app.pages.configurator.filter.labels.label2' | translate}}</mat-option>
              <mat-option value="0">
                Neuheit/verbesserte Leistung von Produkt/Service
              </mat-option>
              <mat-option value="1">
                Kundenanpassung
              </mat-option>
              <mat-option value="2">
                Status/Design/Marke
              </mat-option>
              <mat-option value="3">
                Preisvorteil (günstiges Angebot)
              </mat-option>
              <mat-option value="4">
                Kostenreduzierung (in der Wertschöpfung)
              </mat-option>
              <mat-option value="5">
                Risikoreduzierung
              </mat-option>
              <mat-option value="6">
                Zugang zu Produkten/DL
              </mat-option>
              <mat-option value="7">
                Bequemlichkeit (einfacher zu nutzen)
              </mat-option>
            </mat-select>

        </div>
      </div>
      <div class="filters">
      <div class="filter">
           <button class="reset-btn" (click)="reset()" *ngIf="filterPressed "  mat-flat-button
             [matTooltip]="('app.components.toast.reset' | translate)"
             matTooltipPosition="below"
            >
              <mat-icon svgIcon="filter-off-outline" ></mat-icon>
            </button>
          <!-- <mat-icon>restart_alt</mat-icon> -->
          <!-- <p>{{'app.pages.configurator.filter.title' | translate}}</p> -->
      </div>
        <div class="filter-options">
            <mat-select (selectionChange)="checkFilters()"  [(ngModel)]="filterSettings.sales_filter" >
              <mat-option [value]="defaultFilterSettings.filter3" disabled >{{'app.pages.configurator.filter.labels.label3' | translate}}</mat-option>
              <mat-option value="0">
                Internet-Vertrieb
              </mat-option>
              <mat-option value="1">
                Eigene Geschäfte
              </mat-option>
              <mat-option value="2">
                Großhändler
              </mat-option>
              <mat-option value="3">
                Direkter persönlicher Vertrieb
              </mat-option>
              <mat-option value="4">
                Persönliche Assistenz/Betreuung
              </mat-option>
              <mat-option value="5">
                Self-Service & Digitale Unterstützung
              </mat-option>
              <mat-option value="6">
                Communities
              </mat-option>
            </mat-select>

            <mat-select (selectionChange)="checkFilters()"  [(ngModel)]="filterSettings.market_filter" >
              <mat-option [value]="defaultFilterSettings.filter4" disabled >{{'app.pages.configurator.filter.labels.label4' | translate}}</mat-option>
              <mat-option value="0">
                Massenmarkt
              </mat-option>
              <mat-option value="1">
                Nischenmarkt
              </mat-option>
              <mat-option value="2">
                Mehrseitige Plattformen/Märkte
              </mat-option>
            </mat-select>

            <!-- <button (click)="filter()" *ngIf="isFiltered" class="filter-button" mat-raised-button color="primary" >Filtern</button> -->
      </div>
      </div>
      <p class="small" [innerHTML]="'app.pages.configurator.filter.results' | translate: {'size': (models | async).length}"></p>
    </div>
  </section>
  <div class="results container" *ngIf="(models | async).length !== 0; else noResults">
    <p class="small muted">{{'app.pages.configurator.results.info' | translate}}</p>
    <div class="result-cards">
      <mat-card *ngFor="let model of models | async" (click)="openDialog(model)" >
        <div class="content">
          <img [src]="'../../../assets/images/model_icons/' + model.itemid + '.png'" alt="placeholder">
          <div class="info">
            <h3>{{ model.title_german }}</h3>
            <div class="detail">
              <p>{{ (model.description.length>75)? (model.description  | slice:0:75)+'..':(model.description ) }}</p>
              <button>+ Details</button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <ng-template #noResults>
    <div class="container no-results">
      <p>{{'app.pages.configurator.results.noResults' | translate}}</p>
    </div>
  </ng-template>

  <mat-card *ngIf="isLoading" class="spinner" >
    <mat-spinner diameter="30" color="primary"></mat-spinner>
    <p>{{'app.components.toast.loading' | translate}}</p>
  </mat-card>

</div>

<!-- <app-footer></app-footer> -->
