import * as GoalActions from './goal.actions';

let initialState = null;

export function goalStore(state = initialState, action: GoalActions.GoalActions) {
  switch (action.type) {
    case GoalActions.UPDATE_GOAL:
      return {
        ...state,
        ...action.payload,
      };

    case GoalActions.DELETE_GOAL:
      return initialState;

    default:
      return state;
  }
}
