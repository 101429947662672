import * as ModelActions from './selectedModel.actions';

let initialState = null;

export function selectedModel(state = initialState, action: ModelActions.ModelActions) {
  switch (action.type) {
    case ModelActions.UPDATE_MODEL:
      return {
        ...state,
        ...action.payload,
      };

    case ModelActions.DELETE_MODEL:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}
