import { DetailResponse } from './../../../../interfaces/base/response';
import { BusinessModelService } from './../../../../services/businessmodel.service';
import { IBusinessmodel, IRoute } from './../../../../interfaces/businessmodel';
import { Observable } from 'rxjs';
import { UpdateModel } from './../../../../store/selectedModel.actions';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as SelectedModelActions from 'src/app/store/selectedModel.actions';
import * as GoalActions from 'src/app/store/goal.actions';
import * as RouteActions from 'src/app/store/route.actions';
import * as StartpageActions from 'src/app/store/startpage.actions';

import * as fromApp from 'src/app/store/app.reducer';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit, OnChanges {
  @Input() pinData: IBusinessmodel;
  @Input() isPlanner: boolean;
  @Input() isLocked: boolean;

  @Input() update: boolean = false;

  @Output() markerClicked = new EventEmitter<number>();

  readonly defaultPin = '../../../../../assets/images/poi_passive.png';

  selected = false;
  center = false;
  showInfo = false;
  startId: string;

  selectedStore: Observable<IBusinessmodel>;
  goalStore: Observable<IBusinessmodel>;
  hasRoute: Observable<IRoute>;
  models: IBusinessmodel[];
  startPageModels: IBusinessmodel[];

  routing: IRoute;

  steps;

  pinIcon = '../../../../../assets/images/poi_passive.png';

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private bmService: BusinessModelService,
  ) {
    this.goalStore = this.store.select('goal');
    this.hasRoute = this.store.select('route');
    this.store.select('route');

    router.events.subscribe((event: Event) => {
      // see also
      if (event instanceof NavigationEnd) {
        this.checkPin();
      }
    });
  }

  toggleInfo() {
    this.showInfo = false;
    this.pinIcon = this.defaultPin;
  }

  select() {
    if (!this.isPlanner) {
      this.store.dispatch(new SelectedModelActions.UpdateModel(this.pinData));

      this.checkPin();
    } else {
      if (this.pinIcon === '../../../../../assets/images/poi_active_grau.png') {
      } else {
        if (this.isLocked) {
          this.showInfo = !this.showInfo;
          this.checkPin();
        }
      }
    }
  }

  checkPin() {
    let model;
    this.selectedStore.subscribe((res) => (model = res));

    if (window.location.href.includes('/planner')) {
      this.selectedStore
        .subscribe((model: IBusinessmodel) => {
          this.startId = model.itemid;
        })
        .unsubscribe();
      if (
        this.startId === this.pinData.itemid ||
        this.showInfo === true ||
        (this.steps !== null && this.steps.find((step) => step.itemid === this.pinData.itemid))
      ) {
        this.pinIcon = '../../../../../assets/images/poi_active.png';
        return;
      } else {
        if (!this.pinData.in_distance_scope) {
          this.pinIcon = '../../../../../assets/images/poi_active_grau.png';
          return;
        }
        if (!this.pinData.subject_filter_is_ok) {
          this.pinIcon = '../../../../../assets/images/poi_active_grau.png';
          return;
        }

        this.pinIcon = '../../../../../assets/images/poi_passive.png';
        return;
      }
    } else {
      this.markerClicked.emit(Math.random());
      if (this.pinData.itemid === model.itemid) {
        this.pinIcon = '../../../../../assets/images/poi_active.png';
        return;
      } else {
        this.pinIcon = '../../../../../assets/images/poi_passive.png';
        return;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!window.location.href.includes('/planner')) {
      if (changes.update && !changes.update.firstChange) {
        let model;
        this.selectedStore.subscribe((res) => (model = res));
        if (this.pinData.itemid === model.itemid) {
          this.pinIcon = '../../../../../assets/images/poi_active.png';
          return;
        } else {
          this.pinIcon = '../../../../../assets/images/poi_passive.png';
          return;
        }
      }
    }
  }

  ngOnInit(): void {
    this.selectedStore = this.store.select('selectedModel');

    this.hasRoute.subscribe((res) => {
      if (res !== null) {
        this.steps = res.steps;
      } else {
        this.steps = null;
      }
    });

    if (window.location.href.includes('/planner')) {
      this.selectedStore.subscribe((model: IBusinessmodel) => {
        this.startId = model.itemid;
        this.store.select('models').subscribe((res: IBusinessmodel[]) => {
          this.models = res;
        });
        if (model.itemid === this.pinData.itemid) {
          this.selected = true;
          this.center = true;
        }
        if (
          this.route.snapshot.queryParams.goal &&
          this.pinData.itemid === this.route.snapshot.queryParams.goal
        ) {
          this.getRoute();
        }
        this.checkPin();
      });
    }
  }

  getRoute() {
    const searchParams = {
      start_id: this.startId,
      target_id: this.pinData.itemid,
    };
    this.bmService.getRoute(searchParams).subscribe((res: DetailResponse<IRoute>) => {
      this.routing = res.detail;
      this.showInfo = false;
      this.store.dispatch(new RouteActions.UpdateRoute(res.detail));
    });

    this.store.dispatch(new GoalActions.UpdateGoal(this.pinData));

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        model: searchParams.start_id,
        goal: searchParams.target_id,
      },
    });
  }
  isFirst(first: boolean) {
    if (first) {
      return '#00549f';
    } else {
      return '#178038';
    }
  }
  getDirection(itemid: string, isFirst: boolean, coord: 'x1' | 'x2' | 'y1' | 'y2', index: number) {
    const model = this.models.filter((model) => itemid === model.itemid);
    switch (coord) {
      case 'x1':
        if (isFirst) {
          return 'calc(' + model[0].x + '% + ' + 22 + 'px)';
        } else {
          const firstStep = this.routing.steps[index - 1];
          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);

          return 'calc(' + firstModel[0].x + '% + ' + 22 + 'px)';
        }

      case 'y1':
        if (isFirst) {
          return model[0].y + '%';
        } else {
          const firstStep = this.routing.steps[index - 1];

          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);
          return firstModel[0].y + '%';
        }

      case 'x2':
        if (isFirst) {
          return '22px';
        } else {
          const firstStep = this.routing.steps[index];
          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);

          return 'calc(' + firstModel[0].x + '% + ' + 22 + 'px)';
        }

      case 'y2':
        if (isFirst) {
          return '0';
        } else {
          const firstStep = this.routing.steps[index];

          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);
          return firstModel[0].y + '%';
        }
    }
  }
  getDirectRoute(itemid: string, coord: 'x' | 'y') {
    const model = this.models.filter((model) => itemid === model.itemid);
    switch (coord) {
      case 'x':
        return 'calc(' + model[0].x + '% + ' + 22 + 'px)';

      case 'y':
        return model[0].y + '%';
    }
  }
}
