<div class="home">
  <app-map *ngIf="models | async"  [isHome]="true" [hasOverlay]="true" ></app-map>
  <div class="container">
    <h1>{{'app.pages.home.title' | translate}}</h1>
    <p class="info" >{{'app.pages.home.description' | translate}}</p>
    <mat-card *ngIf="(selectedModelStore | async)" >
      <div class="header">

        <img [src]="'../../../assets/images/model_icons/' + (selectedModelStore | async).itemid + '.png'" alt="Placeholder">
        <h3>{{(selectedModelStore | async)?.title_german}}</h3>

      </div>

        <div class="card-content">
          <p [innerHTML]="(selectedModelStore | async)?.description"></p>
          <div *ngIf="(selectedModelStore | async)?.example">
            <h3>Beispiele</h3>
            <p [innerHTML]="(selectedModelStore | async)?.example"></p>
            <div class="links">
              <a *ngFor="let link of (selectedModelStore | async).example_urls; let last = last" target="_blank" [href]="link.url">
                {{ link.title}}<span [innerHTML]="(!last && link.title.length !== 0) ? ',\&nbsp;' : ''" ></span>
              </a>
            </div>
          </div>
          <div *ngIf="!(selectedModelStore | async)?.example">
            <div class="links no-example">
              <h3>Beispiele:</h3>
              <a *ngFor="let link of (selectedModelStore | async).example_urls; let last = last" target="_blank" [href]="link.url">
                {{ link.title}}<span [innerHTML]="(!last && link.title.length !== 0) ? ',\&nbsp;' : ''" > </span>
              </a>
            </div>
          </div>

        </div>

    </mat-card>
    <button routerLink="/config" mat-raised-button color="primary" >{{'app.pages.home.cta' | translate}}</button>
  </div>
</div>
