import { PlannerModalComponent } from './components/modals/planner-modal/planner-modal.component';
import { environment } from './../environments/environment';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { AppInitService } from './app.init';
import { MaterialModule } from './modules/material.module';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgSkeletonModule } from 'ng-skeleton';
import { ToolbarComponent } from './components/general/toolbar/toolbar.component';
import { MapComponent } from './components/general/map/map.component';
import { ConfiguratorComponent } from './pages/configurator/configurator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessModelDetailComponent } from './components/modals/business-model-detail/business-model-detail.component';
import { PinComponent } from './components/general/map/pin/pin.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

import * as fromAppReducer from './store/app.reducer';
import { PlannerComponent } from './pages/planner/planner.component';
import { IntroSlideshowComponent } from './components/modals/intro-slideshow/intro-slideshow.component';
import { FooterComponent } from './components/general/footer/footer.component';
import { NgxCaptureModule } from 'ngx-capture';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MapRouteComponent } from './components/general/map/maproute/maproute.component';
import { AdminComponent } from './pages/admin/admin.component';
import { FinishComponent } from './pages/finish/finish.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

@NgModule({
  declarations: [
    MapRouteComponent,
    AppComponent,
    ToolbarComponent,
    MapComponent,
    HomeComponent,
    ConfiguratorComponent,
    BusinessModelDetailComponent,
    PlannerModalComponent,
    PinComponent,
    PlannerComponent,
    IntroSlideshowComponent,
    FooterComponent,
    AdminComponent,
    FinishComponent,
  ],
  entryComponents: [BusinessModelDetailComponent, PlannerModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgSkeletonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptureModule,
    StoreModule.forRoot(fromAppReducer.appReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useFactory: createTranslateLoader,
      },
    }),
    NgxSliderModule,
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
