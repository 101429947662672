<img class="pin" [src]="pinIcon" [alt]="pinData?.title_german" (click)="select()"
  [ngClass]="{'center': center, 'disabled': !isLocked, 'disable': pinIcon.includes('poi_active_grau.png')}"
  [matTooltip]="pinData?.title_german" [matTooltipClass]="'blueTooltip'">

<div class="overlay" *ngIf="showInfo" (click)="toggleInfo()"></div>

<mat-card *ngIf="showInfo"
  [ngClass]="{'top': pinData.y >= 45, 'bottom': pinData.y <= 46, 'left': pinData.x <= 45, 'right': pinData.x >= 46}">
  <div class="header">
    <img [src]="'../../../assets/images/model_icons/' + pinData.itemid + '.png'" alt="Placeholder">
    <h3>{{pinData?.title_german}}</h3>
  </div>

  <div class="card-content">
    <p [innerHTML]="pinData?.description"></p>

    <div *ngIf="pinData?.example">
      <h3>Beispiele</h3>
      <p [innerHTML]="pinData?.example"></p>

      <div class="links">
        <span *ngFor="let link of pinData?.example_urls; let last = last">
          <a *ngIf="link.title" [href]="link.url" target="_blank">
            {{ link.title}}<span>{{(!last && link.title !== '') ? ', ' : '' }}</span>
          </a>
        </span>
      </div>
    </div>
    <div *ngIf="!pinData?.example">
      <div class="links no-examples">
        <h3>Beispiele:</h3>
        <span *ngFor="let link of pinData?.example_urls; let last = last">
          <a *ngIf="link.title" [href]="link.url" target="_blank">
            {{ link.title}}<span>{{(!last && link.title !== '') ? ', ' : '' }}</span>
          </a>
        </span>
      </div>
    </div>
    <button mat-flat-button color="primary" (click)="getRoute()">Route(n) zum Ziel-Geschäftsmodell anzeigen</button>
  </div>
</mat-card>
