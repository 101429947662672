<div class="map-wrapper">
  <div class="map" [style.transform]="'scale('+ zoomLevel +')'" >
    <div class="pin-wrapper">
      <div *ngIf="isHome" >
        <div *ngFor="let pin of pins | async">
          <app-pin (markerClicked)="markerClicked($event)" [update]="updatePins" *ngIf="pin.startpage" [pinData]="pin" [ngStyle]="{'top': pin?.y + '%', 'left': pin?.x + '%'}" ></app-pin>
        </div>

        <div *ngIf="hasOverlay" class="overlay" ></div>
      </div>
      <div *ngIf="!isHome" >
        <app-maproute></app-maproute>
        <app-pin [isPlanner]="isPlanner" *ngFor="let pin of pins | async" [pinData]="pin" [ngStyle]="{'bottom': pin?.y + '%', 'left': pin?.x + '%'}" [isLocked]="isLocked" ></app-pin>
      </div>
    </div>
</div>
