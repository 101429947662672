import { StatisticService } from './../../services/statistic.service';
import { Component, OnInit } from '@angular/core';

import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(private statisticService: StatisticService) {}

  ngOnInit(): void {}

  exportData() {
    this.statisticService.getCSV().subscribe((res) => {
      FileSaver.saveAs(res, 'export.csv');
    });
  }
}
