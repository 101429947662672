import { Action } from '@ngrx/store';

export const UPDATE_MODEL = 'UPDATE_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';

export class UpdateModel implements Action {
  readonly type = UPDATE_MODEL;
  constructor(public payload) {}
}

export class DeleteModel implements Action {
  readonly type = DELETE_MODEL;
}

export type ModelActions = UpdateModel | DeleteModel;
