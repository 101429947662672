import { HelperService } from './../helper.service';
import { DetailResponse, ListResponse } from '../../interfaces/base/response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class CrudService {
  // must be set in the child-service
  protected url: string;

  // define custom header for api requests (can be extended in the child-service)
  protected jsonHeaders = {
    headers: HelperService.getDefaultHeaders(),
  };

  protected constructor(protected httpClient: HttpClient) {}

  /* ***** GET methods ***** */

  /**
   * Find one by item id.
   *
   * @param itemId id of the searching entity.
   * @return found entity response object.
   */
  findOne(itemId: string): Observable<DetailResponse<any>> {
    return this.httpClient.get<DetailResponse<any>>(
      `${this.url}/${itemId}`,
      this.jsonHeaders
    );
  }

  /**
   * Get all records.
   *
   * @param searchParams searching query params object.
   * @return object with a list of objects of found records.
   */
  findAll(searchParams?): Observable<ListResponse<any>> {
    const httpParams = HelperService.getHttpParams(searchParams);
    return this.httpClient.get<ListResponse<any>>(this.url, {
      params: httpParams,
      headers: this.jsonHeaders.headers,
    });
  }

  /* ***** POST methods ***** */

  /**
   * Save new entity or edit existing (if id of the existing entity is valid and was set).
   *
   * @param body object of saving entity.
   * @return mapped response object of saved entity.
   */
  save(body: any): Observable<any> {
    return this.httpClient.post<any>(this.url, body, this.jsonHeaders);
  }

  /* ***** DELETE methods ***** */

  /**
   * Delete entity by body.
   *
   * @param body of deleting entity.
   * @return detailed response.
   */
  delete(body: any): Observable<DetailResponse<any>> {
    return this.httpClient.request<DetailResponse<any>>('delete', this.url, {
      body,
      headers: this.jsonHeaders.headers,
    });
  }

  /**
   * Delete entities by bodies.
   *
   * @param body of deleting entity.
   * @return detailed response.
   */
  deleteAll(body: any[]): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, {
      body,
      headers: this.jsonHeaders.headers,
    });
  }
}
