<div class="planner-wrapper">
  <div class="sidemenu" *ngIf="selectedModelStore">
    <h3 *ngIf="goalStore === null">
      {{'app.components.sidemenu.title' | translate }}

    </h3>

    <h3 *ngIf="goalStore !== null">
      Ihre Routen
    </h3>

    <div class="planner-container">
      <div class="icon-bar">
        <mat-icon svgIcon="adjust" class="origin">trip_origin</mat-icon>
        <mat-icon svgIcon="dots-vertical" class="dots"></mat-icon>
        <mat-icon svgIcon="map-marker" class="place"></mat-icon>

      </div>
      <div class="planner">
        <!-- <h5>{{'app.components.sidemenu.yourModel' | translate}}</h5> -->
        <div class="input">
          <span class="black">
            {{'app.components.sidemenu.from' | translate}}
            <span class="blue">
              {{ (selectedModelStore | async)?.title_german }}
            </span>
          </span>
          <!-- <input matInput disabled readonly [value]="selectedModel.title_german" type="text"> -->
        </div>
        <!-- <h5>{{'app.components.sidemenu.wantedModel' | translate}}</h5> -->
        <div class="input second">
          <span class="black">
            {{'app.components.sidemenu.to' | translate}}
            <div class="autocomplete" *ngIf="env.mogree">
              <input (blur)="checkInput()" class="goal" *ngIf="(goalStore | async) === null"
                [ngClass]="{'blue': goalStore !== null}" type="text"
                [placeholder]="'app.components.sidemenu.end' | translate" [formControl]="myControl"
                [matAutocomplete]="auto" />
              <mat-autocomplete (optionSelected)="optionSelected($event)" #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>

            <span *ngIf="!env.mogree && (goalStore | async) === null" class="grey">
              {{'app.components.sidemenu.end' | translate}}
            </span>

            <span *ngIf="goalStore !== null" class="blue">
              {{(goalStore | async)?.title_german}}
            </span>
          </span>
        </div>



        <div class="wrapper" *ngIf="(goalStore | async) === null">
          <div class="info">
            <h3 class="blue">Routenziele einschränken</h3>
            <p class="text-justified">Schränken Sie Ihre möglichen Ziele mit einer oder mehreren der unten angegebenen
              Optionen ein. </p>
          </div>
          <div class="info">
            <h3 class="blue">Entfernung</h3>
            <p class="text-justified">Gemütliche Wandertour oder Gipfelsturm?
              Nutzen Sie den Regler zum Reduzieren der Auswahl auf der Landkarte.
              Die Routenziele nahe an Ihrem Start-Geschäftsmodell (in blau links unten) sind Ihrem bisherigen Muster
              ähnlicher, weiter entfernt gelegene erreichen Sie durch eine umfangreichere Transformation.</p>
          </div>
          <div class="range">
            <!-- <div class="slider-wrapper">
              <mat-label>Minimale Entfernung</mat-label>
              <mat-slider min="1" [max]="maxDistance" [value]="minDistance" step="1" color="primary"
                (input)="distanceChanged($event, true)"></mat-slider>
            </div>
            <div class="slider-wrapper">
              <mat-label>Maximale Entfernung</mat-label>
              <mat-slider min="0" [max]="maxDistance" [value]="maxDistance" step="1" color="primary"
                (input)="distanceChanged($event, false)"></mat-slider>
            </div> -->
            <div class="slider-wrapper">
              <ngx-slider [(value)]="minDistance" [(highValue)]="maxDistance"
                (valueChange)="distanceChanged($event, true)" (highValueChange)="distanceChanged($event, false)"
                [options]="options"> </ngx-slider>

              <span class="min">Min.</span>
              <span class="max">Max.</span>
            </div>
          </div>
          <div class="info">
            <h3 class="blue">
              Themenfelder
            </h3>
            <p class="text-justified">
              Welche Gipfel wollen Sie erklimmen?
              Hier können Sie Geschäftsmodelle anhand ihres Schwerpunkts hervorheben.
              Viele Geschäftsmodelle haben mehrere Themenschwerpunkte. Wählen Sie mehr als ein Feld aus, werden jene
              Geschäftsmodelle hervorgehoben welche die gewählten Schwerpunkte kombinieren.
            </p>
            <div class="wrapper">


              <div class="select-button-wrapper">
                <button [ngClass]="{'active': subjectAreas.area1}" (click)="toggle(0)" class="select-button"
                  mat-flat-button> Geschäftsmodelle mit Fokus Zielgruppe</button>
                <mat-icon (mouseenter)="showInfo = 1" (mouseleave)="showInfo = 0" fontSet="material-icons-outlined">info
                </mat-icon>
              </div>
              <div class="select-button-wrapper">
                <button [ngClass]="{'active': subjectAreas.area2}" (click)="toggle(1)" class="select-button"
                  mat-flat-button> Geschäftsmodelle mit Fokus Nutzenversprechen</button>
                <mat-icon (mouseenter)="showInfo = 2" (mouseleave)="showInfo = 0" fontSet="material-icons-outlined">info
                </mat-icon>
              </div>
              <div class="select-button-wrapper">
                <button [ngClass]="{'active': subjectAreas.area3}" (click)="toggle(2)" class="select-button"
                  mat-flat-button> Geschäftsmodelle mit Fokus Wertschöpfungskette</button>
                <mat-icon (mouseenter)="showInfo = 3" (mouseleave)="showInfo = 0" fontSet="material-icons-outlined">info
                </mat-icon>
              </div>
              <div class="select-button-wrapper">
                <button [ngClass]="{'active': subjectAreas.area4}" (click)="toggle(3)" class="select-button"
                  mat-flat-button> Geschäftsmodelle mit Fokus Erlösmodell</button>
                <mat-icon (mouseenter)="showInfo = 4" (mouseleave)="showInfo = 0" fontSet="material-icons-outlined">info
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div class="wrapper routing-headline" *ngIf="(goalStore | async)?.itemid">
        <h3>Mögliche Routen-Optionen</h3>
      </div>
    </div>
    <div class="route-info" *ngIf="(routeStore | async) !== null">
      <div class="route-header" [ngClass]="(routeStore | async).steps.length === 1 ? 'green' : 'blue'">
        <div class="circle">
          1
        </div>
        <h3>{{(routeStore | async).steps.length === 1 ? 'Direkte Route' : 'Kürzeste Route'}}</h3>
        <h3>Aufwandsindex {{(routeStore | async).full_dijkstra_distance | number: '1.0-0' }}</h3>
      </div>
      <div class="route" *ngFor="let route of (routeStore | async)?.steps"
        [ngClass]="(routeStore | async).steps.length === 1 ? 'green' : 'blue'">
        <div></div>
        von {{route.title}}
        <strong>
          {{route.difficulty}}
        </strong>
      </div>
      <div class="route-info" *ngIf="(routeStore | async).steps.length >= 2">
        <div class="route-header green">
          <div class="circle">
            2
          </div>
          <h3>Direkte Route</h3>
          <h3>Aufwandsindex {{(routeStore | async).full_pythagoras_distance | number: '1.0-0' }}</h3>
        </div>
        <div class="route green">
          <div></div>
          von {{ (selectedModelStore | async)?.title_german + ' zu ' + (goalStore | async)?.title_german }}
          <strong *ngIf="(routeStore | async)?.pythagoras_guide_difficulty ===  1">
            einfach
          </strong>
          <strong *ngIf="(routeStore | async)?.pythagoras_guide_difficulty ===  2">
            anspruchsvoll
          </strong>
          <strong *ngIf="(routeStore | async)?.pythagoras_guide_difficulty ===  3">
            herausfordernd
          </strong>
        </div>
      </div>

      <div class="route info">
        <div></div>
        <div>
          <h3 class="blue">Orientierung und Kartenkunde</h3>
          <p class="text-justified">
            Lieber Wanderer, der Aufwandsindex gibt nicht die zurückzulegenden Kilometer oder die Anzahl an möglichen
            Schleichwegen bis zu Ihrem Ziel-Geschäftsmodell an, sondern berschreibt die Komplexität der Veränderung zum
            Ziel-Geschäftsmodell und beträgt maximal 80.
            <br>
            Die Strecke vom Start zum Ziel kann über eine direkte Route, oder in Spezialfällen auch über eine weniger
            komplexe Alternativroute mit Zwischenstops, beganngen werden.
            <br>
            Die Aufwandsindexe der Gesamtroute sind in unterschiedliche Kategorien gegliedert: einfach, herausfordernd
            oder anspruchsvoll.
            <br>
            Je nach Komplexität der Teilstrecke steht Ihnen ein passendes Ausrüstungsset zur Verfügung.
            <br>
            <br>
            Die benötigte Ausrüstung sowie Ihr gesamter Weg vom Start bis ans Ziel steht in Ihrem persönlichen
            Wanderführer zum Download bereit.
          </p>
          <button mat-raised-button color="primary" (click)="download()">Download des Wanderführers</button>
        </div>
      </div>

    </div>
  </div>

  <div id="map">

    <app-map [isPlanner]="true" [isHome]="false" [zoomLevel]="zoomLevel" [isLocked]="isLocked"></app-map>
  </div>

  <mat-card class="info-card" *ngIf="showInfo !== 0">
    <mat-icon fontSet="material-icons-outlined">info</mat-icon>
    <div [ngSwitch]="showInfo">
      <p *ngSwitchCase="1">
        Wer sind Ihre ZielkundInnen?
        Einige Geschäftsmodellmuster fokussieren sich in besonderem Maße auf die jeweilige Zielgruppe oder auf bestimmte
        KundInnensegmente.
        <br>
        <br>
        Heben Sie mit dieser Auswahl Modelle mit besonderer KundInnenzentrierung hervor.
      </p>
      <p *ngSwitchCase="2">
        Was wird den KundInnen angeboten?
        Das Nutzenversprechen eines Unternehmens beschreibt alle Leistungen eines Unternehmens (Produkte
        und Dienstleistungen), die KundInnen von Nutzen sind.
        <br>
        <br>
        Finden Sie mit dieser Auswahl Geschäftsmodellmuster mit Wertangeboten als deren Schwerpunkt.
      </p>
      <p *ngSwitchCase="3">
        Wie wird die Leistung erstellt?
        Um ein Nutzenversprechen zu erzielen, muss ein Unternehmen verschiedene Prozesse und Aktivitäten
        durchführen. Die Wertschöpfungskette beinhaltet diese Prozesse und Aktivitäten zusammen mit den dafür
        notwendigen Ressourcen und Fähigkeiten, sowie die Koordination all dieser Einzelteile.
        <br>
        <br>
        Fokussieren Sie mit dieser Auswahl Geschäftsmodellmuster, welche sich auf die Wertschöpfungskette
        spezialisieren.
      </p>
      <p *ngSwitchCase="4">
        Wie wird Wert erzielt?
        Einige der Geschäftsmodellmuster fokussieren auf das Erlösmodell eines Unternehmens. Dieses beinhaltet Aspekte
        wie die Kostenstruktur und die Umsatzmechanismen.
        <br>
        <br>
        Heben Sie hier Geschäftsmodellmuster hervor, welche den Fokus auf Wertgenerierung für alle beteiligten Parteien
        legen.
      </p>
    </div>
  </mat-card>

</div>

<div class="controls">
  <button [disabled]="zoomLevel > 2" (click)="zoom()" mat-flat-button>
    <mat-icon svgIcon="plus" class="mat-18"></mat-icon>
  </button>
  <button [disabled]="zoomLevel === 1" (click)="zoomOut()" mat-flat-button>
    <mat-icon svgIcon="minus" class="mat-18"></mat-icon>
  </button>
</div>
<!--
<mat-card *ngIf="(goalStore | async)?.itemid" class="goal">
  <h4>Mit folgender Ausrüstung gelangen Sie leichter ans Ziel</h4>
  <div class="route" *ngFor="let route of (routeStore | async)?.steps">
    <p>
      {{route.title}}
    </p>
    <strong>
      {{route.difficulty}}
    </strong>
  </div>
  <p class="info">
    Alle Details zu Ihrer Ausrüstigung finden Sie gesammelt in Ihrem Wanderführer im Download
  </p>
</mat-card> -->


<div class="link" *ngIf="screenshot !== ''">
  {{screenshot}}
</div>
