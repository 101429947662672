<div mat-dialog-title>
  <div class="icon">
    <img [src]="'../../../../assets/images/model_icons/' + data.model.itemid + '.png'" alt="Placeholder">
    <mat-icon mat-dialog-close >close</mat-icon>
  </div>
  <h2 >{{data.model.title_german}}</h2>

</div>
<mat-dialog-content>
  <div class="description">
    <h3>{{'app.components.popup.description' | translate}}</h3>
    <p [innerHTML]="data.model.description"></p>
  </div>
  <div class="examples" *ngIf="data.model?.example" >
    <h4>Beispiele</h4>
    <p [innerHTML]="data.model.example"></p>
    <div class="links">
      <a *ngFor="let link of data.model.example_urls; let last = last" target="_blank" [href]="link.url">
        {{ link.title }}<span>{{ (!last && link.title !== '') ? ', ' : '' }}</span>
      </a>
    </div>
  </div>

  <div class="examples" *ngIf="!data.model?.example" >
    <div class="links no-examples">
      <h4>Beispiele:</h4>
      <a *ngFor="let link of data.model.example_urls; let last = last" target="_blank" [href]="link.url">
        {{ link.title }}<span>{{ (!last && link.title !== '') ? ', ' : '' }}</span>
      </a>
    </div>
  </div>

  <div class="cta-container">
    <button (click)="selectModel()" mat-dialog-close class="cta" mat-raised-button color="primary">{{'app.components.popup.cta' | translate}}</button>
  </div>

</mat-dialog-content>

<div class="controls">
  <button [disableRipple]="true" *ngIf="(allModels | async)[0].index !== data.model.index"  (click)="pref()" mat-flat-button class="prev">
    <mat-icon >chevron_left</mat-icon>
  </button>
  <button [disableRipple]="true" *ngIf="(allModels | async)[models.length-1].index !== data.model.index"  (click)="next()" mat-flat-button class="next">
    <mat-icon >chevron_right</mat-icon>
  </button>
</div>
