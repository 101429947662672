import { Action } from '@ngrx/store';

export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const DELETE_ROUTE = 'DELETE_ROUTE';

export class UpdateRoute implements Action {
  readonly type = UPDATE_ROUTE;
  constructor(public payload) {}
}

export class DeleteRoute implements Action {
  readonly type = DELETE_ROUTE;
}

export type RouteActions = UpdateRoute | DeleteRoute;
