import * as StatisticActions from 'src/app/store/statistic.actions';
import { IStatistic } from './../../../interfaces/statistic.interface';
import { IBusinessmodel } from './../../../interfaces/businessmodel';
import { Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import * as SelectedModelActions from 'src/app/store/selectedModel.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-model-detail',
  templateUrl: './business-model-detail.component.html',
  styleUrls: ['./business-model-detail.component.scss'],
})
export class BusinessModelDetailComponent implements OnInit {
  allModels: Observable<IBusinessmodel[]>;
  models: IBusinessmodel[];
  modelLength: number;
  constructor(
    private dialogRef: MatDialogRef<BusinessModelDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.allModels = this.store.select('models');
    this.allModels.subscribe((res) => {
      this.models = res;
    });
  }

  ngOnInit(): void {}
  selectModel() {
    const statistic: IStatistic = {
      start_bm: this.data.model.itemid,
      market_filter: this.data.filters.market_filter,
      revenue_model_filter: this.data.filters.revenue_model_filter,
      sales_filter: this.data.filters.sales_filter,
      value_proposition_filter: this.data.filters.value_proposition_filter,
    };

    this.store.dispatch(new SelectedModelActions.UpdateModel(this.data.model));
    this.store.dispatch(new StatisticActions.UpdateStatistic(statistic));
    this.router.navigate(['/planner'], { queryParams: { model: this.data.model.itemid } });
  }
  pref() {
    this.data.model = this.models[this.data.model.index - 1];
  }
  next() {
    this.data.model = this.models[this.data.model.index + 1];
  }
}
