import { Action } from '@ngrx/store';

export const UPDATE_GOAL = 'UPDATE_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';

export class UpdateGoal implements Action {
  readonly type = UPDATE_GOAL;
  constructor(public payload) {}
}

export class DeleteGoal implements Action {
  readonly type = DELETE_GOAL;
}

export type GoalActions = UpdateGoal | DeleteGoal;
