import { IBusinessmodel } from './../../interfaces/businessmodel';
import { IntroSlideshowComponent } from './../../components/modals/intro-slideshow/intro-slideshow.component';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import * as fromApp from '../../store/app.reducer';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  environment = environment;
  selectedModelStore: Observable<IBusinessmodel>;
  models: Observable<IBusinessmodel[]>;

  constructor(private store: Store<fromApp.AppState>) {
    this.selectedModelStore = this.store.select('selectedModel');
    this.models = this.store.select('map');
  }
  ngOnInit() {}
}
