<div mat-dialog-title>
  <h2 >{{'app.components.popup.planner.title' | translate}}</h2>
  <h2 >{{'app.components.popup.planner.subtitle' | translate}}</h2>
</div>
<mat-dialog-content>
  <div class="description">
    <!-- <p [innerHTML]="'app.components.popup.planner.description' | translate"></p> -->
    <p>
      Herzliche Gratulation, Sie haben Ihr derzeitiges Geschäftsmodell identifiziert und festgelegt. Sie sehen Ihr Geschäftsmodell nun blau hervorgehoben links unten auf der Landkarte. </p>
      <br>
      <p>Auf der Karte sehen Sie auch die 54 anderen Geschäftsmodelle, die Ihrem näher oder ferner liegen. </p>
      <br>
      <p>Ihr Ziel-Geschäftsmodell können Sie nun frei wählen oder mit Hilfe des Konfigurators festlegen.
    </p>
  </div>

  <div class="cta-container">
    <button mat-dialog-close class="cta" mat-raised-button color="primary">{{'app.components.popup.planner.cta' | translate }}</button>
  </div>

</mat-dialog-content>
