import { IRoute, IBusinessmodel } from './../../../../interfaces/businessmodel';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';

import * as fromApp from 'src/app/store/app.reducer';

@Component({
  selector: 'app-maproute',
  templateUrl: './maproute.component.html',
  styleUrls: ['./maproute.component.scss'],
})
export class MapRouteComponent implements OnInit {
  selected = false;
  center = false;
  showInfo = false;
  startId: string;
  goalId: string;

  selectedStore: Observable<IBusinessmodel>;
  goalStore: Observable<IBusinessmodel>;
  hasRoute: Observable<IRoute>;
  models: IBusinessmodel[];
  startPageModels: IBusinessmodel[];

  routing: IRoute;

  constructor(private store: Store<fromApp.AppState>) {
    this.goalStore = this.store.select('goal');
    this.hasRoute = this.store.select('route');
    this.store.select('models').subscribe((res) => {
      this.models = res;
    });
    this.hasRoute.subscribe((res) => {
      this.routing = res;
    });
  }

  ngOnInit() {}

  getDirection(itemid: string, isFirst: boolean, coord: 'x1' | 'x2' | 'y1' | 'y2', index: number) {
    const model = this.models.filter((model) => itemid === model.itemid);
    switch (coord) {
      case 'x1':
        if (isFirst) {
          return 'calc(' + model[0].x + '% + ' + 22 + 'px)';
        } else {
          const firstStep = this.routing.steps[index - 1];
          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);

          return 'calc(' + firstModel[0].x + '% + ' + 22 + 'px)';
        }

      case 'y1':
        if (isFirst) {
          return model[0].y + '%';
        } else {
          const firstStep = this.routing.steps[index - 1];

          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);
          return firstModel[0].y + '%';
        }

      case 'x2':
        if (isFirst) {
          return '22px';
        } else {
          const firstStep = this.routing.steps[index];
          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);

          return 'calc(' + firstModel[0].x + '% + ' + 22 + 'px)';
        }

      case 'y2':
        if (isFirst) {
          return '0';
        } else {
          const firstStep = this.routing.steps[index];

          const firstModel = this.models.filter((model) => firstStep.itemid === model.itemid);
          return firstModel[0].y + '%';
        }
    }
  }
  getDirectRoute(itemid: string, coord: 'x' | 'y') {
    const model = this.models.filter((model) => itemid === model.itemid);
    switch (coord) {
      case 'x':
        return 'calc(' + model[0].x + '% + ' + 22 + 'px)';

      case 'y':
        return model[0].y + '%';
    }
  }
}
