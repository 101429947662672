import { Action } from '@ngrx/store';

export const UPDATE_MODELS = 'UPDATE_MODELS';
export const DELETE_MODELS = 'DELETE_MODELS';

export class UpdateModel implements Action {
  readonly type = UPDATE_MODELS;
  constructor(public payload) {}
}

export class DeleteModel implements Action {
  readonly type = DELETE_MODELS;
}

export type ModelsActions = UpdateModel | DeleteModel;
