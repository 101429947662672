import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-slideshow',
  templateUrl: './intro-slideshow.component.html',
  styleUrls: ['./intro-slideshow.component.scss'],
})
export class IntroSlideshowComponent implements OnInit {
  activeSlide = 0;
  interval;

  constructor() {}

  startTimer() {
    this.interval = setInterval(() => {
      if (this.activeSlide !== 5) {
        this.activeSlide++;
      } else {
        clearInterval(this.interval);
      }
    }, 8000);
  }

  ngOnInit(): void {
    this.startTimer();
  }
}
