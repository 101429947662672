import * as ModelActions from './startpage.actions';

const initialState = [];

export function startpageStore(state = initialState, action: ModelActions.ModelsActions) {
  switch (action.type) {
    case ModelActions.UPDATE_MODELS:
      return [...action.payload];

    default:
      return state;
  }
}
